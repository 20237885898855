<template>
  <div v-frag>
    <page-title title="CSV feltöltés"></page-title>
    <!-- NEM JOGOSULT MEGTEKINTENI AZ OLDALT START -->
    <template v-if="!isUserCanSeeTheForm">
      <v-container class="my-12">
        <v-row class="my-12 fill-height" align="center" justify="center">
          <v-col cols="12" class="text-center mb-5">
            <h2>
              Ön nem jogosult megtekinteni a kért oldalt!
            </h2>
          </v-col>
          <v-col cols="auto">
            <v-btn class="primary" @click="$router.push({name: 'iranyitopult'})">
              Vissza a kezdőlapra
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- NEM JOGOSULT MEGTEKINTENI AZ OLDALT END -->
    <!-- JOGOSULT MEGTEKINTENI AZ OLDALT START -->
    <template v-if="isUserCanSeeTheForm">
      <v-container class="my-10">
        <v-row>
          <v-col cols="12">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae feugiat elit. In sit amet enim eget sapien tempor bibendum sit amet eu lacus.
              Maecenas quis tellus nec lectus efficitur pellentesque. Cras consequat ligula a magna laoreet cursus. Ut mollis scelerisque condimentum.</p>
            <p>Nullam laoreet massa sit amet sapien ullamcorper, a aliquet nibh tempor. Nullam placerat malesuada elit id varius.</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-show="errorCard.visible">
        <v-row class="fill-height">
          <v-col cols="12">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-table-arrow-down</v-icon>
                <span class="subtitle-1">{{ $t('excel.errorMessages') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <template v-for="(item, index) in errorCard.message">
                    <p :key="index" class="error--text">
                      {{ item }}
                    </p>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row class="fill-height">
          <v-col cols="12" md="4">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-table-arrow-down</v-icon>
                <span class="subtitle-1">{{ $t('excel.downloadSamples') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="8" align-self="center" class="subtitle-1">
                    {{ $t('excel.downloadSampleCSV') }}
                  </v-col>
                  <v-col cols="4">
                    <v-row class="mx-0" justify="end" align="center">
                      <v-btn color="primary" @click="downloadSample(1)">
                        {{ $t('button.download') }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="8" align-self="center" class="subtitle-1">
                    {{ $t('excel.downloadExcelHelper') }}
                  </v-col>
                  <v-col cols="4">
                    <v-row class="mx-0" justify="end" align="center">
                      <v-btn color="primary" @click="downloadSample(2)">
                        {{ $t('button.download') }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="8">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-table-arrow-up</v-icon>
                <span class="subtitle-1">{{ $t('excel.uploadFiles') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12">
                    <v-file-input v-model="files"
                                  :label="$t('inputLabels.selectFilesToUpload')"
                                  :hint="$t('excel.acceptFiles')"
                                  persistent-hint
                                  accept=".csv,.xls,.xlsx"
                                  counter
                                  show-size
                                  filled
                                  ref="excelFiles">
                      <template v-slot:selection="{text}">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="uploadCsv" :disabled="!files || files.length === 0">
                  {{ $t('button.upload') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- LOADING, RESPONSE DIALOGS START -->
      <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
        <loading-dialog v-bind:loading-message="loadingDialog.message" />
      </v-dialog>
      <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
        <response-dialog v-bind:response-object="responseDialog.response"
                         v-on:close-dialog="closeResponseDialog"
                         v-bind:button-text="responseDialog.buttonText"/>
      </v-dialog>
      <!-- LOADING, RESPONSE DIALOGS END -->
    </template>
    <!-- JOGOSULT MEGTEKINTENI AZ OLDALT END -->
  </div>
</template>

<script>
import PageTitle from 'src/components/titles/PageTitle';
import LoadingDialog from 'src/components/dialogs/LoadingDialog';
import ResponseDialog from 'src/components/dialogs/ResponseDialog';

export default {
  name: 'InnerExcelUploadPage',
  components: {
    PageTitle, LoadingDialog, ResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    this.isUserCanSeeTheForm = await this.CheckIsUserCanSeeTheForm();
  },
  data() {
    return {
      isUserCanSeeTheForm: false,
      files: [],
      errorCard: {
        visible: false,
        message: [],
      },
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CSVUPLOADING',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
        buttonText: 'OK',
      },
    };
  },
  methods: {
    async CheckIsUserCanSeeTheForm() {
      if (this.$store.state.authModule.isAuthenticated) {
        // console.log('isAuthenticated');
        if (this.$store.state.authModule.user) {
          // console.log('user');
          return this.$store.state.authModule.user.systemParams.excelUploadIsEnabled;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    downloadSample(id) {
      if (id === 1) {
        window.open('/csv/flotta-tesztadatok-20200916.csv', '_self');
      } else {
        window.open('/csv/flotta-minta-20200915.xlsx', '_self');
      }
    },
    async uploadCsv() {
      this.openLoadingDialog();
      this.errorCard.visible = false;
      this.errorCard.message = [];
      const response = await this.$store.dispatch('UploadCsv',
          { csvFile: this.files });
      // console.log('uploadCsv response: ', response);
      await this.processResponseMessage(response.message);
      /*if (response.status === 200) {
        await this.processResponseMessage(response.message);
      } else if(response.status === 404) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>Váratlan hiba történt!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Kérjük próbálja meg a feltöltést újra!</div><div>Köszönjük türelmét!</div>';
      } else {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>Megszakadt a kapcsolat!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Kérjük próbálja meg a feltöltést újra!</div><div>Köszönjük türelmét!</div>';
      }*/
      this.controlDialogs();
    },
    async processResponseMessage(message) {
      // console.log('processResponseMessage start...');
      // console.log('message: ', message);
      if (message.length > 0) {
        this.responseDialog.response.message = `<div class="display-1 mb-6"><b>Hiba a feltöltött fájlban!</b></div><div class="headline mb-4">A fájl feldolgozása során hibákat találtunk, amelyeket kérjük javítson és utána próbálja meg újra a feltöltést!</div><div>Köszönjük megkeresését!</div>`;
        this.responseDialog.buttonText = 'Hibák megtekintése';
        // message splittelése ;-ként
        this.errorCard.message = message.split(';');
      } else {
        this.responseDialog.response.message = `<div class="display-1 mb-6"><b>Sikeres feltöltés!</b></div><div class="headline mb-4">A fájlok feltöltése sikeres volt.</div><div>Köszönjük megkeresését!</div>`;
      }
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      if (this.responseDialog.buttonText === 'OK') {
        this.$router.push({ name: 'iranyitopult'} );
      } else {
        this.responseDialog.visible = false;
        this.errorCard.visible = true;
      }
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
  },
};
</script>

<style scoped>

</style>
